import React from "react";

import styles from "./styles/Layout.module.scss"

import Menu from "./Menu";

import { Col, Row } from "react-bootstrap";
import Details from "./Details";

export default function DesktopLayout({ route, setRoute, pages}) {
    return (
        <div className={styles.Layout}>
            <Row className={styles.row}>
                <Col
                    className={styles.col}
                    md={3}
                >
                    <Menu route={route} setRoute={setRoute} pages={pages} />
                </Col>
                <Col
                    className={styles.col}
                    md={9}
                >
                    <Details route={route} pages={pages} />
                </Col>

            </Row>

        </div>
    )
}