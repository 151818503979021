import { configureStore } from "@reduxjs/toolkit";
//
import authSlice from "./Data/features/authSlice";
import dashboardReducer from "./Data/features/dashboardSlice";
import postReportReducer from "./Data/features/postReportSlice";
import postReducer from "./Data/features/postSlice";
import settingsReducer from "./Data/features/settingsSlice";
import subscriptionReducer from "./Data/features/subscriptionSlice";
import userReducer from "./Data/features/userSlice";
//
import sessionMiddleware from "./Data/middleware/sessionMiddleware";
import { dashboardApi } from "./Data/services/dashboardApi";
import { notificationApi } from "./Data/services/notificationApi";
import { postApi } from "./Data/services/postApi";
import { postReportApi } from "./Data/services/postReportApi";
import { settingApi } from "./Data/services/settingApi";
import { subscriptionApi } from "./Data/services/subscriptionApi";
import { userApi } from "./Data/services/userApi";
import { userAuthApi } from "./Data/services/userAuthApi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    // [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [postReportApi.reducerPath]: postReportApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    //
    //
    // Local storage Redux
    auth: authSlice,
    dashboard: dashboardReducer,
    setting: settingsReducer,
    user: userReducer,
    // subscription: subscriptionReducer,
    posts: postReducer,
    postReport: postReportReducer,
  },
  // load from local storage
  // preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userAuthApi.middleware,
      dashboardApi.middleware,
      notificationApi.middleware,
      settingApi.middleware,
      userApi.middleware,
      // subscriptionApi.middleware,
      postApi.middleware,
      postReportApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:
