import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { IoEyeSharp } from "react-icons/io5";
import { Input, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";

//
import { uniqueId } from "lodash";
import { Col, Row } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import LazyLoad from "react-lazy-load";
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import CustomSpinner from "../../Components/CustomComponents/CustomSpinner";
import ImageWithPlaceholder from "../../Components/CustomComponents/ImageWithPlaceholder";
import DeleteModal from "../../Components/Modals/DeleteModal";
import ViewPostModal from "../../Components/Modals/ViewPostModal";
import {
  useDeletePostMutation,
  useGetAllPostsQuery,
} from "../../Data/services/postApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "../../Components/Switch/Switch";
import {
  useGetAllGroupsQuery,
  useGroupStatusMutation,
} from "../../Data/services/groupApi";
import { Link } from "react-router-dom";
// import { useGetAllPostsQuery } from "../../services/postApi";
// import { updatePosts } from "../../features/postSlice";

const GroupManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [viewData, setViewData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1); // todo
  const [pageSize, setPageSize] = useState(10); // todo
  const [totalRecords, setTotalRecords] = useState(0); // todo
  const [totalPages, setTotalPages] = useState(0); // todo
  const [dataFetched, setDataFetched] = useState(false); // todo
  const [isChecked, setIsChecked] = useState(false);

  const [groupStatus, {}] = useGroupStatusMutation();

  const onActiveSet = async (data) => {
    try {
      const response = await groupStatus(data?.id);

      console.log(response);

      if (!response.error && response.data.code === 200) {
        setIsChecked(!isChecked);
        SuccessToast(response?.data.message);
        refetch();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        console.warn(error);

        Toast("An error occurred during send message", "error");
      }
    }
  };

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllGroupsQuery(
      {
        search: searchQuery.length >= 3 ? searchQuery : "",
        limit: pageSize,
        page: currentPage,
      },
      { refetchOnMountOrArgChange: true }
    );
  console.log(data);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // var allPosts = useSelector((state) => state.posts.data);
  // console.log(`allPosts = ${allPosts}`);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setPageSize(data.links.per_page);
      setCurrentPage(data.links.current);
      setTotalRecords(data.links.total_records);
      setTotalPages(data.links.total);
      setRowData(data.data);
      setDataFetched(true);
    }
  }, [data]);

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setViewData("");
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setViewData("");
  };

  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();

  const deleteData = async (id) => {
    // console.log(editedData.slug);
    // console.log(id);

    const postSlug = id;

    try {
      const { data } = await deletePost(postSlug);

      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      dataField: "image_url",
      text: "Group Image",
      // sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <div key={uniqueId()}>
            {
              <LazyLoad
                key={uniqueId()}
                height={100}
                width={100}
                threshold={0.95}
                debounce={500}
                onContentVisible={() => {
                  // console.log("loaded!");
                }}
              >
                <ImageWithPlaceholder
                  src={row.image_url}
                  placeholder={"images/placeholder.webp"}
                  alt="post"
                  className="rounded-3"
                  height={100}
                  width={100}
                />
              </LazyLoad>
            }
          </div>
        );
      },
    },
    {
      dataField: "name",
      text: "Group Name",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return <td>{`${row?.name || ""}`}</td>;
      },
    },
    {
      dataField: "members",
      text: "Group Members",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.members.length > 0) {
          return (
            <div className="d-flex">
              {row.members?.slice(0, 12).map((member, index) => {
                return (
                  <div
                    key={index}
                    title={`${member?.user?.firstname || ""} ${
                      member?.user?.lastname || ""
                    }`}
                    style={{ marginLeft: index >= 1 && "-12px" }}
                  >
                    <ImageWithPlaceholder
                      src={member?.user?.image_url}
                      placeholder={"images/placeholder.webp"}
                      alt="post"
                      className="rounded-circle"
                      height={40}
                      width={40}
                    />
                    {`${member?.name || ""}`}
                  </div>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      dataField: "is_blocked",
      text: "Status",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "50px",
      },
      formatter: (cellContent, row) => {
        // if (row.is_blocked) {
        //   return <div className={styles.active}> Active </div>;
        // } else {
        //   return <div className={styles.block}>Block</div>;
        return (
          <FormControlLabel
            control={
              <IOSSwitch
                checked={row?.status === "active" ? true : false}
                onChange={() => {
                  // setIsChecked((prev) => !prev)
                  onActiveSet(row);
                }}
              />
            }
            title={row?.status !== "active" ? "Block" : "Active"}
          />
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "180px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <Link
                to={`${process.env.REACT_APP_DOMAIN_DIR}/group-post-management/${row.id}`}
              >
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    setViewData(row);
                    handleViewModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <IoEyeSharp />
                  </span>
                  <span> View Group Posts</span>
                </div>
              </Link>
              {/* <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setViewData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div> */}
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={viewData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}
      {viewModal ? (
        <ViewPostModal
          handleViewModalClose={handleViewModalClose}
          data={viewData}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <Row className="w-100">
          <Col md={6}>
            <div>
              <SubTitle text={"Group Management"} classes="primary-text" />
            </div>
          </Col>
          <Col md={6}>
            <Input
              placeholder="search by name"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {!dataFetched ? (
          <CustomSpinner />
        ) : (
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
            totalRecords={totalRecords} // todo
            totalPages={totalPages} // todo
            pageSize={pageSize} // todo
            currentPage={currentPage} // todo
            setCurrentPage={setCurrentPage} // todo
            setPageSize={setPageSize} // todo
            refetch={refetch} // todo
          />
        )}
      </div>
    </div>
  );
};

export default GroupManagementScreen;
