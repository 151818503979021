import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import { useAddOrUpdateStaticPagesMutation } from "../../Data/services/settingApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

const ContactUs = () => {
  const [adminEmail, setAdminEmail] = useState("");

  const handleAdminEmail = (e) => {
    // console.log(e.target.value);
    setAdminEmail(e.target.value);
  };

  var settingData = useSelector((state) => state.setting.pages.CONTACT);

  const [updatePolicy, { isLoading }] = useAddOrUpdateStaticPagesMutation();

  useEffect(() => {
    if (settingData !== null) {
      setAdminEmail(settingData?.description);
    }
    // setLink(e.target.value);
  }, [settingData]);

  const handleSaveText = async () => {
    // console.log(value);
    const data = {
      type: "CONTACT",
      description: adminEmail,
      // link: link,
    };
    // console.log(data);

    try {
      const response = await updatePolicy(data);

      if (response.data.code === 200) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="Contact Us" classes="mb-4 primary-text" />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} ${isLoading ? "bg-dark-subtle" : ""}`}
            text={isLoading ? <BeatLoader size={10} /> : "Save"}
            onClick={handleSaveText}
            disabled={isLoading}
          ></Button>
        </Col>
      </Row>
      <p className="black-text">
        Set your email, App user will contact you on this email.
      </p>
      <Row>
        <Col md={12}>
          <p className="m-0 black-text">Email</p>
          <Input
            classes="mt-2 mb-3"
            type="email"
            placeholder="Enter Admin Email"
            value={adminEmail}
            onChange={handleAdminEmail}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
