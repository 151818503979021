import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { FaDatabase } from "react-icons/fa";
import { useSelector } from "react-redux";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { ROUTES } from "./constants";
import { useGetDashboardDetailsQuery } from "../../Data/services/dashboardApi";
import MonthlyPostsChart from "../../Components/MonthlyPostGraph/MonthlyPostGraph";
import styl from '../../Components/Cards/DashboardCard';

export default function Dashboard({ setPage }) {
  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetDashboardDetailsQuery();

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="primary-text" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#004C95"}
            bg2={"#002951"}
            icon={<FaDatabase color="#002951" />}
            number={data == null ? 0 : data?.data?.users_count}
            text={"Numbers of Users"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#004C95"}
            bg2={"#002951"}
            icon={<FaDatabase color="#002951" />}
            number={data == null ? 0 : data?.data?.total_active_posts}
            text={"Numbers of Active Posts"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#004C95"}
            bg2={"#002951"}
            icon={<FaDatabase color="#002951" />}
            number={data == null ? 0 : data?.data?.total_groups}
            text={"Numbers of Groups"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#004C95"}
            bg2={"#002951"}
            icon={<FaDatabase color="#002951" />}
            number={data == null ? 0 : data?.data?.total_active_questions}
            text={"Numbers of Active Questions"}
          />
        </Col>
        {
          // <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          //   <DashboardCard
          //     bg1={"#00BFFF"}
          //     bg2={"#87CEFA"}
          //     icon={<FaDatabase color="#00BFFF" />}
          //     number={data == null ? 0 : data.subscription_list}
          //     text={"Numbers of Subscriber"}
          //   />
          // </Col>
          // <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          //   <DashboardCard
          //     bg1={"#32CD32"}
          //     bg2={"#90EE90"}
          //     icon={<FaDatabase color="#32CD32" />}
          //     number={data == null ? 0 : data.communities}
          //     text={"Numbers of Communities"}
          //   />
          // </Col>
          // <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          //   <DashboardCard
          //     bg1={"#9932CC"}
          //     bg2={"#9370DB"}
          //     icon={<FaDatabase color="#9932CC" />}
          //     number={data == null ? 0 : data.pet_community.count}
          //     text={"Missing Pets Communities"}
          //   />
          // </Col>
          // <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          //   <DashboardCard
          //     bg1={"#008080"}
          //     bg2={"#00FFFF"}
          //     icon={<FaDatabase color="#008080" />}
          //     number={data == null ? 0 : data.person_community.count}
          //     text={"Missing Person Communities"}
          //   />
          // </Col>
        }
      </Row>
      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col md={12}>
          <div className={`Chart ${styl.card}`}>
            <MonthlyPostsChart />
          </div>
        </Col>
      </Row>
    </div>
  );
}
