import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useGetDashboardDetailsQuery } from "../../Data/services/dashboardApi";
import { SubTitle } from "../CustomComponents";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyPostsChart = () => {
  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetDashboardDetailsQuery();

  const chartData = {
    labels: data?.data?.monthly_posts_data?.map((item) => item.month),
    datasets: [
      {
        label: "Total Orders - Line",
        data: data?.data?.monthly_posts_data?.map((item) => item.totalOrders),
        borderColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;
          if (!chartArea) return "rgba(75, 192, 192, 1)";

          const gradient = ctx.createLinearGradient(
            chartArea.left,
            chartArea.top,
            chartArea.right,
            chartArea.bottom
          );
          gradient.addColorStop(0, "#002951");
          gradient.addColorStop(1, "#004c95");

          return gradient;
        },
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
      },
    //   title: {
    //     display: true,
    //     text: "Monthly Post",
    //     align: "start",
    //     fontSize: 30,
    //     fontStyle: "bold",
    //   },
      tooltip: {
        callbacks: {
          label: (context) => `Orders: ${context.raw}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const defaultOptions = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        position: "top",
        align: "center",
      },
      subtitle: {
        display: true,
        text: "Custom Chart Subtitle",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return `${(value / 1000).toFixed(1)}k`;
            }
            return value;
          },
        },
      },
    },
  };
  //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //     datasets: [
  //         {
  //             label: "",
  //             data: data,
  //             fill: true,
  //             backgroundColor: "rgba(75,192,192,0.2)",
  //             borderColor: "rgba(75,192,192,1)",
  //         },
  //     ],
  // };

  const options = chartOptions || defaultOptions;

  return (
    <div className="wrapper">
      {isLoading || !data ? (
        <loadder
          size={20}
          color="#0000FF"
          className="d-flex justify-content-center align-items-center h-100"
        />
      ) : (
        <>
          <SubTitle text="Monthly Posts" classes="fs-4 primary-text mb-4"/>
          <Line
            options={options}
            data={chartData || { labels: [], datasets: [] }}
          />
        </>
      )}
    </div>
  );
};

export default MonthlyPostsChart;
