import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { IoEyeSharp } from "react-icons/io5";
import { Input, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";

//
import _, { uniqueId } from "lodash";
import { Col, Form, Row } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import LazyLoad from "react-lazy-load";
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import CustomSpinner from "../../Components/CustomComponents/CustomSpinner";
import ImageWithPlaceholder from "../../Components/CustomComponents/ImageWithPlaceholder";
import DeleteModal from "../../Components/Modals/DeleteModal";
import ViewPostModal from "../../Components/Modals/ViewPostModal";
import {
  useDeletePostMutation,
  useGetAllPostsQuery,
  usePostStatusMutation,
} from "../../Data/services/postApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { FormControlLabel } from "@mui/material";
import { IOSSwitch } from "../../Components/Switch/Switch";
import { Controller, useForm } from "react-hook-form";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import { useParams } from "react-router-dom";
// import { useGetAllPostsQuery } from "../../services/postApi";
// import { updatePosts } from "../../features/postSlice";

const PostManagementScreen = ({ setPage }) => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const [viewData, setViewData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1); // todo
  const [pageSize, setPageSize] = useState(10); // todo
  const [totalRecords, setTotalRecords] = useState(0); // todo
  const [totalPages, setTotalPages] = useState(0); // todo
  const [dataFetched, setDataFetched] = useState(false); // todo
  const [isChecked, setIsChecked] = useState(false);
  const { handleSubmit, control } = useForm({});
  const [selectedStatus, setSelectedStatus] = useState("Post");
  const statusList = [
    { label: "Question", value: "question" },
    { label: "Post", value: "post" },
  ];

  const [postStatus, {}] = usePostStatusMutation();

  const onActiveSet = async (data) => {
    try {
      const response = await postStatus(data?.id);

      console.log(response);

      if (!response.error && response.data.code === 200) {
        setIsChecked(!isChecked);
        SuccessToast(response?.data.message);
        refetch();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        console.warn(error);

        Toast("An error occurred during send message", "error");
      }
    }
  };

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPostsQuery(
      {
        search: searchQuery.length >= 3 ? searchQuery : "",
        limit: pageSize,
        page: currentPage,
        post_type: selectedStatus,
        group_id: id ? id : "",
      },
      { refetchOnMountOrArgChange: true }
    );

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // var allPosts = useSelector((state) => state.posts.data);
  // console.log(`allPosts = ${allPosts}`);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setPageSize(data.links.per_page);
      setCurrentPage(data.links.current);
      setTotalRecords(data.links.total_records);
      setTotalPages(data.links.total);
      setRowData(data.data);
      setDataFetched(true);
    }
  }, [data]);

  const handleViewModalOpen = () => {
    setViewModal(true);
  };

  const handleViewModalClose = () => {
    setViewModal(false);
    setViewData("");
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setViewData("");
  };

  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();

  const deleteData = async (id) => {
    // console.log(editedData.slug);
    // console.log(id);

    const postSlug = id;

    try {
      const { data } = await deletePost(postSlug);

      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      dataField: "attachments",
      text: "Post Image",
      // sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return (
          <div key={uniqueId()}>
            {
              <LazyLoad
                key={uniqueId()}
                height={100}
                width={100}
                threshold={0.95}
                debounce={500}
                onContentVisible={() => {
                  // console.log("loaded!");
                }}
              >
                <ImageWithPlaceholder
                  src={
                    row.attachments?.length > 0 &&
                    (row.attachments[0]?.thumbnail_url ||
                      row.attachments[0]?.media_url)
                  }
                  placeholder={"images/placeholder.webp"}
                  alt="post"
                  className="rounded-3"
                  height={100}
                  width={100}
                />
              </LazyLoad>
            }
          </div>
        );
      },
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return (
          <td>{`${row?.user?.firstname || ""} ${
            row?.user?.lastname || ""
          } `}</td>
        );
      },
    },
    {
      dataField: "total_likes",
      text: "Post Likes",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return (
          <td>{row?.total_likes}</td>
        );
      },
    },
    {
      dataField: "total_shares",
      text: "Post Shares",
      sort: true,
      // headerAlign: "center",
      // align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return (
          <td>{row?.total_shares}</td>
        );
      },
    },
    {
      dataField: "is_blocked",
      text: "Status",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // if (row.is_blocked) {
        //   return <div className={styles.active}> Active </div>;
        // } else {
        //   return <div className={styles.block}>Block</div>;
        return (
          <FormControlLabel
            control={
              <IOSSwitch
                checked={row?.status === "active" ? true : false}
                onChange={() => {
                  // setIsChecked((prev) => !prev)
                  onActiveSet(row);
                }}
              />
            }
            title={row?.status !== "active" ? "Block" : "Active"}
          />
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.user?.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setViewData(row);
                  handleViewModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <IoEyeSharp />
                </span>
                <span>View</span>
              </div>
              {/* <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setViewData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div> */}
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={viewData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}
      {viewModal ? (
        <ViewPostModal
          handleViewModalClose={handleViewModalClose}
          data={viewData}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <Row className="w-100">
          <Col md={6}>
            <div>
              <SubTitle text={id ? "Group Post Management" :"Post Management"} classes="primary-text" />
            </div>
          </Col>
          <Col md={6}>
            <Row className="w-100">
              <Col md={8}>
                <Input
                  placeholder="search by name"
                  value={searchQuery}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </Col>
              <Col md={4}>
              <Controller
                  control={control}
                  name="post_type"
                  rules={{}}
                  defaultValue={selectedStatus}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SelectFieldComponent
                      firstOption={
                        _.isEmpty(selectedStatus) ? "Select Type" : selectedStatus
                      }
                      optionsList={statusList}
                      onSelectChange={(newValue) => {
                        onChange(newValue); 
                        setSelectedStatus(newValue);
                      }}
                    />
                  )}
                />

              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {!dataFetched ? (
          <CustomSpinner />
        ) : (
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
            totalRecords={totalRecords} // todo
            totalPages={totalPages} // todo
            pageSize={pageSize} // todo
            currentPage={currentPage} // todo
            setCurrentPage={setCurrentPage} // todo
            setPageSize={setPageSize} // todo
            refetch={refetch} // todo
          />
        )}
      </div>
    </div>
  );
};

export default PostManagementScreen;
