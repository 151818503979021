import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdDeleteForever, MdOutlineBlock } from "react-icons/md";
import { Description } from "../CustomComponents";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";
import { formatDate } from "../../Helper";

const ReportComponent = ({ onClickDeleteButton, onClickBlockButton, data, isDeleted, setIsDeleted }) => {
  const styles = { border: "1px solid grey", backgroundColor: "#1212121a" };

  const [isBlocked, setIsBlocked] = useState(false);

  return (
    <div>
      <div className="d-flex flex-column justify-content-between align-items-start p-2 ">
        <div className="d-flex flex-row justify-content-start w-100 mb-2">
          <Row className="w-100">
            <Col sm={12} md={4}>
              <div className="rounded-4 py-2 px-3 w-100" style={styles}>
                <Description
                  text="Reporter :"
                  classes="fs-7 gray-text fw-bold mb-2"
                />
                <UserAvatarWithNameAndTag
                  userName={`${data?.reported_user?.firstname} ${data?.reported_user?.lastname}`}
                  //   userTag={data.reporter.userTag}
                  avatarImage={data?.reported_user?.image_url}
                />
              </div>
            </Col>
            {data.post ? (
              <Col sm={12} md={8}>
                <div className="rounded-4 py-2 px-3 w-100" style={styles}>
                  <Description
                    text="Reportee :"
                    classes="fs-7 text-danger fw-bold mb-2"
                  />
                  <Description
                    text={data?.post?.content}
                    classes="fs-6 lc-1 fw-bold mb-2 black-text"
                  />
                  <img
                    src={data?.post?.attachments[0]?.media_url}
                    alt="post"
                    width={200}
                    height={100}
                    style={{ objectFit: "cover" }}
                    className="rounded-3"
                  />

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    {
                      <Description
                        // text={`Post created at: ${data.reportee.post.postCreated}`}
                        text={""}
                        classes="fs-8 gray-text fw-bold mb-2"
                      />
                    }

                    <UserAvatarWithNameAndTag
                      userName={`${data?.post?.user?.firstname} ${data?.post?.user?.lastname}`}
                      //   userTag={data.reportee.user.userTag}
                      avatarImage={data?.post?.user?.image_url}
                    />
                  </div>
                </div>
              </Col>
            ) : null}

            {/* {
              data?.post?.user? (
                <Col sm={12} md={{ span: 4, offset: 4 }}>
                  <div className="rounded-4 py-2 px-3 w-100" style={styles}>
                    <Description
                      text="Reportee :"
                      classes="fs-7 text-danger fw-bold mb-2"
                    />
                    <UserAvatarWithNameAndTag
                      userName={`${data?.post?.user?.firstname} ${data?.post?.user?.lastname}`}
                      // userTag={data.reportee.user.userTag}
                      avatarImage={data?.post?.user?.image_url}
                    />
                  </div>
                </Col>
              ) : null
            } */}
          </Row>
        </div>

        <Description
          classes={`fs-8 gray-text`}
          text={`Date: ${formatDate(data.createdAt)}`}
        />

        <Description text="Message :" classes="fs-7 gray-text fw-bold my-2" />

        <Description text={data.description} classes=" gray-text" />

        <div className="d-flex flex-row justify-content-end align-items-center w-100">
          <div
            className="d-flex flex-column me-2 align-items-center border-1 px-2 rounded-3"
            style={{ border: "1px solid grey" }}
          >
            <span
              className={`fw-bold fs-8 
                ${data.status === "unresolved" ? "text-danger" : ""} 
                ${data.status === "resolved" ? "text-warning" : ""}`}
            >
              {data.status}
            </span>
            <span className="gray-text fs-8">Status</span>
          </div>
          {data.status === "unresolved" ? (
            <>
              {/* <div
                className={`d-flex rounded-3 p-2 mx-2 ${
                  isBlocked ? "bg-white-subtle" : ""
                }`}
                role="button"
                onClick={() => {
                  // console.log(data);
                  setIsBlocked(true);
                  onClickBlockButton(data);
                }}
              >
                <span className={`d-flex align-items-center pe-1 text-white`}>
                  <MdOutlineBlock />
                </span>
                <span className="text-white">
                  {isBlocked ? "Blocking..." : "Block"}
                </span>
              </div> */}
              <div
                className={`d-flex rounded-3 p-2 mx-2 ${
                  isDeleted ? "bg-danger-subtle" : "bg-danger"
                }`}
                role="button"
                onClick={() => {
                  // console.log(data);
                  setIsDeleted(true);
                  onClickDeleteButton(data);
                }}
              >
                <span className={`d-flex align-items-center pe-1 text-white`}>
                  <MdDeleteForever />
                </span>
                <span className="text-white">
                  {isDeleted ? "Resolving..." : "Resolve"}
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportComponent;
