import moment from "moment";
const baseRoute = (name = '') => {
    return `${process.env.REACT_APP_DOMAIN_DIR}/${name}`
}

const apiBaseUrl = () => {
    return process.env.REACT_APP_API_DOMAIN + 'api/user/'
}

const localStorageToken = () => {
    return process.env.REACT_APP_WEB_STORAGE_TOKEN;
}


export const formatDate = (date) => {
    return moment(date).format('LL');
  };
  


export { baseRoute, apiBaseUrl, localStorageToken }







