import React, { useEffect, useState } from "react";

import { BREAKPOINTS } from "../../constants";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";
import { ROUTES } from "./contants";
import { useGetStaticPagesQuery } from "../../Data/services/settingApi";

export default function Setting() {
  const [route, setRoute] = useState(ROUTES.CHANGE_PASSWORD);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= BREAKPOINTS.MOBILE
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => window.removeEventListener("resize", handleWindowSizeChange);
  });

  // inject data
  const { data, isSuccess, refetch } = useGetStaticPagesQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    refetch()
  }, [route])

  function handleWindowSizeChange() {
    if (!isMobile && window.innerWidth <= BREAKPOINTS.MOBILE) {
      setRoute("");
    } else if (isMobile && window.innerWidth > BREAKPOINTS.MOBILE) {
      setRoute(ROUTES.EDIT_PROFILE);
    }
    setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE);
  }

  return isMobile ? (
    <MobileLayout route={route} setRoute={setRoute} pages={data?.data}/>
  ) : (
    <DesktopLayout route={route} setRoute={setRoute} pages={data?.data} />
  );
}
