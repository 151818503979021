import React from 'react';

import styles from "./styles/AccountLayout.module.scss"

import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";


import { ShadowContainer } from '../CustomComponents';

export default function AccountLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children
}) {
    return (
        <div className={styles.AccountLayout}
        // style={{ backgroundImage: `url(/images/account_bg.png)` }}
        >
            <ShadowContainer />
            <Container className={styles.sectionContainer}>

                <Row className={styles.row}>
                    <Col
                        md={12}
                        className={`${styles.formContainer}`}
                    >
                        <img src='/images/logo_name.png' alt="Logo" height={80} width={80} className={`${styles.image} ${styles.logo}`} />
                        {/* <h6 className="secondary-text fw-semibold mt-2">Listcrime</h6> */}
                        <br></br>
                        <div className={styles.form}>
                            {hasBackButton ? <div className={styles.backButtonContainer} onClick={handleBackButton}>
                                <IoIosArrowBack />
                            </div> : ""
                            }

                            {children}
                        </div>
                    </Col>
                    {
                        // <Col
                        // md={6}
                        // className={`${styles.mediaContainer}`}
                        // >
                        // <img src='/images/logo_assets.png' alt='Logo Assets' className={styles.image} />
                        // <img src='/images/logo.png' alt="Logo" className={`${styles.image} ${styles.logo}`} />
                        // </Col>
                    }

                </Row>
            </Container>

        </div>
    )
}